.adyen-checkout__qr-loader {
  background: var(--adyen-sdk-color-background-primary, #ffffff);
  padding: var(--adyen-sdk-spacer-110, 40px);
  padding-bottom: var(--adyen-sdk-spacer-090, 24px);
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-outline-secondary, #c9cdd3);
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.adyen-checkout__qr-loader--result {
  padding: 100px;
  gap: var(--adyen-sdk-spacer-090, 24px);
}

.adyen-checkout__qr-loader--app {
  border: 0;
  border-radius: 0;
  padding: 0;
}

.adyen-checkout__qr-loader__brand-logo-wrapper {
  border-radius: var(--adyen-sdk-border-radius-s, 4px);
  box-shadow: var(--adyen-sdk-shadow-low, 0px 2px 4px rgba(0, 17, 44, 0.04), 0px 1px 2px rgba(0, 17, 44, 0.02));
  margin-bottom: var(--adyen-sdk-spacer-090, 24px);
  overflow: hidden;
}

.adyen-checkout__qr-loader__brand-logo {
  width: 80px;
  display: block;
}

.adyen-checkout__qr-loader__subtitle {
  max-width: 400px;
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-size: var(--adyen-sdk-text-body-font-size, 0.875rem);
  line-height: var(--adyen-sdk-text-body-line-height, 20px);
  text-align: center;
}

.adyen-checkout__qr-loader__icon {
  width: 88px;
  height: 88px;
}

.adyen-checkout__qr-loader__payment_amount {
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-weight: var(--adyen-sdk-text-title-l-font-weight, 600);
  font-size: var(--adyen-sdk-text-title-l-font-size, 1.5rem);
  line-height: var(--adyen-sdk-text-body-wide-line-height, 24px);
  margin-bottom: var(--adyen-sdk-spacer-090, 24px);
}

.adyen-checkout__qr-loader__progress {
  height: var(--adyen-sdk-spacer-020, 4px);
  background: var(--adyen-sdk-color-outline-secondary, #c9cdd3);
  border-radius: var(--adyen-sdk-border-radius-l, 12px);
  width: 152px;
  padding-right: 3%;
  margin-bottom: var(--adyen-sdk-spacer-060, 12px);
}
[dir=rtl] .adyen-checkout__qr-loader__progress {
  padding-right: 0;
  padding-left: 3%;
}

.adyen-checkout__qr-loader__percentage {
  display: block;
  height: 100%;
  border-radius: var(--adyen-sdk-border-radius-l, 12px);
  background: var(--adyen-sdk-color-label-primary, #00112c);
}

.adyen-checkout__qr-loader__countdown {
  font-size: var(--adyen-sdk-text-caption-font-size, 0.75rem);
  text-align: center;
}

.adyen-checkout__qr-loader > .adyen-checkout__spinner__wrapper {
  margin: 60px 0;
}

.adyen-checkout__qr-loader__app-link {
  display: none;
}

.adyen-checkout__button.adyen-checkout__button--qr-loader {
  text-decoration: none;
  margin-top: var(--adyen-sdk-spacer-090, 24px);
}

.adyen-checkout__qr-loader__instructions {
  max-width: 300px;
  color: var(--adyen-sdk-color-label-tertiary, #5c687c);
  font-size: var(--adyen-sdk-text-subtitle-font-size, 1rem);
  line-height: 1.5;
  margin-top: var(--adyen-sdk-spacer-090, 24px);
}

.adyen-checkout__qr-loader__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--adyen-sdk-spacer-100, 32px);
}

.adyen-checkout__await__instructions {
  display: flex;
  justify-content: center;
}

@media (max-width: 1024px) {
  .adyen-checkout__qr-loader__app-link {
    display: block;
    min-width: 220px;
  }
}