.adyen-checkout__await {
  background: var(--adyen-sdk-color-background-primary, #ffffff);
  padding: var(--adyen-sdk-spacer-110, 40px);
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-outline-secondary, #c9cdd3);
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  text-align: center;
}

.adyen-checkout__await--result {
  padding: 100px;
}

.adyen-checkout__qr-loader--app {
  border: 0;
  border-radius: 0;
  padding: 0;
}

.adyen-checkout__await__brand-logo {
  width: 74px;
  border-radius: 3px;
}

.adyen-checkout__await__subtitle,
.adyen-checkout__await__indicator-text {
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-size: var(--adyen-sdk-text-subtitle-font-size, 1rem);
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  margin-top: var(--adyen-sdk-spacer-100, 32px);
}

.adyen-checkout__await__indicator-holder .adyen-checkout__await__indicator-text {
  margin-top: var(--adyen-sdk-spacer-030, 6px);
  margin-left: var(--adyen-sdk-spacer-050, 10px);
}

.adyen-checkout__await__indicator-holder {
  display: flex;
  justify-content: center;
  margin-top: var(--adyen-sdk-spacer-100, 32px);
  margin-bottom: var(--adyen-sdk-spacer-080, 20px);
}

.adyen-checkout__await__subtitle--result {
  margin-bottom: var(--adyen-sdk-spacer-100, 32px);
}

.adyen-checkout__await__icon {
  width: 88px;
  height: 88px;
}

.adyen-checkout__await__progress {
  height: var(--adyen-sdk-spacer-020, 4px);
  background: var(--adyen-sdk-color-outline-secondary, #c9cdd3);
  border-radius: var(--adyen-sdk-border-radius-l, 12px);
  margin: var(--adyen-sdk-spacer-100, 32px) auto var(--adyen-sdk-spacer-060, 12px);
  width: 152px;
}

.adyen-checkout__await__percentage {
  display: block;
  height: 100%;
  border-radius: var(--adyen-sdk-border-radius-l, 12px);
  background: var(--adyen-sdk-color-label-primary, #00112c);
}

.adyen-checkout__await__countdown {
  color: var(--adyen-sdk-color-label-secondary, #5c687c);
  font-size: var(--adyen-sdk-text-body-font-size, 0.875rem);
}

.adyen-checkout__await > .adyen-checkout__spinner__wrapper {
  margin: 60px 0;
}

.adyen-checkout__await__app-link {
  margin-top: var(--adyen-sdk-spacer-070, 16px);
  display: none;
}

.adyen-checkout__await__amount {
  margin-top: var(--adyen-sdk-spacer-070, 16px);
  font-size: var(--adyen-sdk-text-title-l-font-size, 1.5rem);
  font-weight: var(--adyen-sdk-text-title-font-weight, 600);
  text-align: center;
}

@media only screen and (max-device-width: 1200px) {
  .adyen-checkout__await__app-link {
    display: block;
  }
}