.adyen-checkout-ctp__otp-resend-code-wrapper {
  margin-left: auto;
  top: 0;
  right: 0;
  position: absolute;
  line-height: var(--adyen-sdk-text-body-line-height, 20px);
}

.adyen-checkout-ctp__otp-resend-code--disabled,
.adyen-checkout-ctp__otp-resend-code--confirmation {
  pointer-events: none;
  font-size: var(--adyen-sdk-text-body-font-size, 0.875rem);
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  margin-left: auto;
  color: var(--adyen-sdk-color-label-tertiary, #5c687c);
  cursor: default;
}

.adyen-checkout-ctp__otp-resend-code--confirmation {
  display: flex;
  align-items: center;
}
.adyen-checkout-ctp__otp-resend-code--confirmation > img {
  margin-left: var(--adyen-sdk-spacer-020, 4px);
}

.adyen-checkout-ctp__otp-resend-code-counter {
  font-size: var(--adyen-sdk-text-body-font-size, 0.875rem);
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  margin-left: auto;
  cursor: default;
  color: var(--adyen-sdk-color-label-primary, #00112c);
  display: inline-block;
  text-align: right;
}

.adyen-checkout-ctp__section > .adyen-checkout__field.adyen-checkout__field--otp {
  margin-bottom: var(--adyen-sdk-spacer-060, 12px);
}

.adyen-checkout-ctp__otp-field-wrapper {
  position: relative;
}